@use '@styles/_mixin.scss' as *;

:root {
  --header-desktop-height: 300px;
  --header-mobile-height: 64px;
  --footer-height: 54px;
  --black: #222;
  --light-gray: rgb(34 34 34 / 20%);
  --gray: rgb(34 34 34 / 40%);
  --extra-light-gray: rgb(34 34 34 / 10%);
  --white: #fff;
  --green-1: #083b22;
  --green-2: #095134;
  --green-3: #1e7451;
  --light-green: rgb(8 59 34 / 10%);
  --extra-light-green: rgb(8 59 34 / 5%);
  --pink-1: #965b50;
  --pink-2: #d88c7e;
  --pink-3: #ecb6ac;
  --light-pink: #fdf3f2;
  --red: #e22d2d;
  --font-serif: 'IBM Plex Serif' !important;
  --font-sans: 'IBM Plex Sans' !important;
}

::placeholder,
:input-placeholder {
  color: var(--black);
  font-family: var(--font-serif);
  font-size: 20px;
  letter-spacing: -0.1px;
}

html {
  min-height: calc(100% + env(safe-area-inset-top));
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom)
    env(safe-area-inset-left);
}

body {
  font-family: var(--font-sans);
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
}

/* Change the white to any color */
input.ant-input:-webkit-autofill,
input.ant-input:-webkit-autofill:hover,
input.ant-input:-webkit-autofill:focus,
input.ant-input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px white inset !important;
}

.ant-modal-close {
  background-color: rgb(243 245 244) !important;
  border-radius: 32px !important;
  height: 50px !important;
  margin-top: 16px;
  width: 50px !important;

  svg {
    color: var(--green-3);
    font-weight: bold;
  }
}

/* Custom CSS for Ant Components */

/* ----Buttons */
.ant-btn-link:not(:disabled):hover {
  color: var(--green-3) !important;
}

.ant-btn:not(.ant-btn-link) {
  background-color: var(--white);
  border: solid 1px var(--light-gray);
  border-radius: 32px;
  color: var(--black);
  font-family: var(--font-sans);
  font-size: 16px;
  height: 45px;

  &:disabled {
    background-color: var(--white);
    color: var(--gray);
    opacity: 1;

    svg {
      fill: var(--gray) !important;
    }
  }

  &.wisory-btn-default {
    min-width: 145px;
  }

  &.ant-btn-primary {
    background-color: var(--green-3);
    border: none;
    color: var(--white);
    font-weight: bold;

    &:disabled,
    &.ant-btn-loading {
      background-color: var(--light-gray) !important;
      color: var(--gray) !important;
      opacity: 1;

      svg {
        fill: var(--gray);
      }
    }
  }

  &.ant-btn-primary:not(:disabled, .ant-btn-disabled):hover,
  &.ant-btn-primary:not(:disabled, .ant-btn-disabled):active {
    background-color: var(--green-2);
    color: var(--white);
  }

  &.ant-btn-default:not(:disabled, .ant-btn-disabled):hover,
  &.ant-btn-default:not(:disabled, .ant-btn-disabled):active {
    background-color: var(--extra-light-green);
    border: solid 1px var(--light-gray);
    color: var(--black);
  }
}

.ant-btn.ant-btn-link {
  color: var(--green-3);
  font-family: var(--font-sans);
  font-size: 16px;
  line-height: 1.5;
}

/* Inputs */
.ant-form-item .ant-form-item-explain-error {
  color: var(--red);
  font-family: var(--font-sans);
  font-size: 12px;
  margin-top: 10px;
  text-align: right;
}

textarea.ant-input {
  background-color: var(--white);
  border: solid 1px var(--light-gray);
  border-radius: 8px;
}

input[type='text'].ant-input,
.ant-input-password,
.ant-input-number,
.react-tel-input .form-control,
.ant-picker,
.ant-select-selector {
  border-radius: 8px;
  font-family: var(--font-serif);
  font-size: 20px;
  height: 60px;

  @include for-phone-only() {
    font-size: 18px;
    height: 50px;
  }
}

.ant-input:focus,
.ant-input-focused,
.ant-input-affix-wrapper:focus,
.ant-picker-focused.ant-picker,
.ant-select:not(
    .ant-select-disabled,
    .ant-select-customize-input,
    .ant-pagination-size-changer
  ):hover
  .ant-select-selector,
.ant-picker:hover,
.ant-input:hover {
  border: solid 2px #1e7451 !important;
  box-shadow: 0 4px 10px 0 rgb(0 0 0 / 10%);
  color: inherit !important;
}

/* stylelint-disable-next-line no-descending-specificity */
.ant-select-selector {
  padding: 6px 11px !important;
}

.ant-input-number-input {
  height: 45px;
}

.react-tel-input {
  input {
    width: 100%;
  }

  .flag-dropdown {
    border-radius: 8px 0 0 8px;
  }
}

.ant-radio-group {
  .ant-radio-button-wrapper-checked {
    background-color: var(--green-3) !important;
    border-color: var(--green-3) !important;

    &:hover {
      color: var(--white) !important;
    }
  }
}

.ant-radio-button-wrapper:hover {
  color: var(--green-3) !important;
}

/* Tags */

.ant-select-multiple {
  /* stylelint-disable-next-line no-descending-specificity */
  .ant-select-selector {
    padding: 12px !important;
  }

  .ant-select-selection-item {
    border: solid 1px var(--light-gray);
    border-radius: 24px;
    height: 40px;
    line-height: 38px;
    margin-inline-end: 8px;
    margin-top: 8px;
    padding-inline: 12px 12px;
  }
}

.ant-tag {
  align-items: center;
  border-radius: 60px;
  display: flex;
  font-family: var(--font-sans);
  font-size: 16px;
  height: 40px;
  min-width: 70px;
  padding: 12px;
}

/* Card */

.ant-card {
  border: solid 1px #e7e7e7;
  border-radius: 8px;
  box-shadow: 0 5px 10px 0 rgb(0 0 0 / 5%);

  .ant-card-head {
    border-bottom: none;
  }
}

/* List */

.ant-list {
  .ant-list-header {
    border-bottom: none;
  }

  .ant-list-item-meta {
    align-items: center !important;
  }

  .ant-list-item-meta-description {
    color: var(--black) !important;
    font-size: 16px !important;
  }

  .ant-list-item-meta-title {
    color: var(--black) !important;
    font-size: 16px !important;
    font-weight: bold !important;
    margin-top: 0 !important;
  }
}

/* Typography */

h2.ant-typography,
h3.ant-typography,
h4.ant-typography {
  color: var(--green-3);
}

h2.ant-typography {
  font-family: var(--font-serif);
  font-size: 44px;
  font-weight: normal;
}

h3.ant-typography {
  font-family: var(--font-sans);
  font-size: 26px;
  font-weight: bold;
}

h4.ant-typography {
  font-family: var(--font-sans);
  font-size: 22px;
  font-weight: bold;
}

.ant-form-item-label > label,
.ant-form .ant-form-item-label label {
  font-size: 16px;
  font-weight: bold;
}

.ant-form-item-label {
  padding: 0 0 10px;
}

@media only screen and (width <= 600px) {
  h2.ant-typography {
    font-size: 24px;
  }

  h4.ant-typography {
    font-size: 18px;
  }
}

.ant-divider {
  border-top: 1px solid var(--light-gray);
}

.ant-modal-content {
  padding: 40px !important;
}

.ant-input-password {
  padding: 0;
  padding-right: 12px;

  &.ant-input-affix-wrapper:hover,
  &.ant-input-affix-wrapper-focused {
    border: 2px solid #1e7451;
    border-inline-end-width: 2px;
  }

  input {
    border-radius: 8px !important;
    height: 56px !important;
    padding-left: 12px !important;

    &.ant-input:hover,
    &.ant-input:focus {
      border: none !important;
    }

    @include for-phone-only() {
      height: 46px !important;
    }
  }
}

.ant-btn:not(:disabled):focus-visible {
  box-shadow: 0 4px 10px 0 rgb(0 0 0 / 10%);
  outline: none;
}

/* Form */
