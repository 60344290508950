@use '@styles/_mixin.scss' as *;

.otpInput {
  border: 1px solid rgb(0 0 0 / 30%);
  border-radius: 7.2px;
  color: var(--green-3);
  font-family: var(--font-sans);
  font-size: 26px;
  font-weight: bold;
  height: 60px !important;
  margin-right: 13px;
  outline: none;
  padding: 0;
  width: 60px !important;

  &:focus,
  &.hasValue {
    border: solid 2px var(--green-3);
    height: 58px !important;
    width: 58px !important;

    @include for-phone-only() {
      height: 46px !important;
      width: calc(16% - 4px) !important;
    }
  }

  &.lastItem {
    margin-right: 0;
  }

  @include for-phone-only() {
    height: 48px !important;
    margin-right: 8px;
    width: 16% !important;
  }
}

.summaryWrapper {
  margin-bottom: 40px;
}

.submitBtn {
  width: 120px;
}

.backBtn {
  width: 115px;
}

.loginTitle {
  margin-bottom: 16px;
  margin-top: 50px;

  @include for-phone-only() {
    margin-top: 30px;
  }
}

.loginSubtitle {
  margin-bottom: 50px;
  margin-top: 0;

  @include for-phone-only() {
    margin-bottom: 30px;
    margin-top: 16px;
  }
}

.buttonWrapper {
  margin-top: 40px;
}

.resendWrapper {
  margin-top: 60px;

  .resendBtn {
    margin: 0;
    padding: 0;

    span {
      text-decoration: underline !important;

      &:hover {
        text-decoration: none !important;
      }
    }
  }
}
