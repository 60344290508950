@use '@styles/_mixin.scss' as *;

.subtitle {
  color: var(--black);
  display: block;
  font-family: var(--font-serif);
  font-size: 20px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: -0.1px;
  line-height: 1.6;
  margin: 0;

  &.small {
    font-size: 16px;
  }

  &.default {
    font-size: 20px;

    @include for-phone-only() {
      font-size: 16px !important;
    }
  }

  &.large {
    font-size: 22px;
  }
}

.subtitleMarginTop {
  margin-top: 20px;
}

.subtitleMarginBottom {
  margin-bottom: 20px;
}

.title {
  color: var(--green-3);
  font-family: var(--font-serif);
  font-weight: normal;
  margin: 0;
  white-space: normal;

  &.small {
    font-size: 22px;
  }

  &.default {
    font-size: 26px;

    @include for-phone-only() {
      font-size: 20px !important;
      letter-spacing: -0.2px;
    }
  }

  &.large {
    font-size: 44px;
  }

  &.x-large {
    font-size: 66px;
    letter-spacing: -2px;

    @include for-phone-only() {
      font-size: 28px !important;
      letter-spacing: -0.6px;
    }
  }
}

.bold {
  font-weight: bold;
}

.titleMarginBottom {
  margin-bottom: 40px;
}

.titleMarginTop {
  margin-top: 40px;
}

.dialogTitle {
  color: var(--green-3);
  font-family: var(--font-serif);
  font-size: 26px;
  font-weight: normal;
  font-weight: bold;
  margin: 0;
}

.dialogTitleMarginBottom {
  margin-bottom: 12px;
}

.dialogTitleMarginTop {
  margin-top: 12px;
}

.label {
  font-family: var(--font-sans);
  font-size: 16px;
  font-weight: bold;
  margin: 0;
}
