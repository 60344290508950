@use '@styles/_mixin.scss' as *;

.subtitle {
  margin-bottom: 50px;
  margin-top: 0;

  @include for-phone-only() {
    margin-bottom: 30px;
  }
}

.loginTitle {
  margin-bottom: 16px;
  margin-top: 50px;

  @include for-phone-only() {
    margin-top: 30px;
  }
}

.buttonWrapper {
  margin-top: 40px;
}

.summaryWrapper {
  margin-bottom: 40px;
}
