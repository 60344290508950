.wrapper {
  align-items: flex-start;
  border-radius: 8px;
  display: flex;
  font-family: var(--font-sans);
  gap: 20px;
  padding: 20px;

  &.success {
    background-color: #f3f5f4;
  }

  &.error {
    background-color: var(--light-pink);
  }
}
