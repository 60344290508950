.wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 100%;
  justify-content: center;
  width: 100%;
}

.message {
  color: var(--green-3);
  font-family: var(--font-sans);
  font-size: 16px;
  font-weight: bold;
}

.loader {
  animation: rotation 1s linear infinite;
  border: 5px solid #1e7451;
  border-bottom-color: transparent;
  border-radius: 50%;
  box-sizing: border-box;
  display: inline-block;
  height: 50px;
  width: 50px;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
