@use '@styles/_mixin.scss' as *;

.wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100vh;

  @media (width <= 1440px) {
    background-size: auto;
  }
}

.buttonGroup {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.loginButton {
  align-items: center;
  border: solid 1px var(--light-gray);
  border-radius: 60px;
  cursor: pointer;
  display: flex;
  height: 60px;
  max-width: 440px;

  .icon {
    margin-left: 24px;
  }

  .text {
    color: var(--black);
    flex: auto;
    font-family: var(--font-sans);
    font-size: 18px;
    text-align: center;
  }
}

.intro {
  margin-bottom: 50px;
  margin-top: 50px;

  @include for-phone-only() {
    margin-bottom: 30px;
    margin-top: 30px;
  }
}

.content {
  margin: 0 auto;
  max-width: 440px;
  padding: 0 30px 30px;
}

.header {
  background-color: #095134;
  background-image: url('./images/WisoryHeaderBackground.svg');
  background-repeat: no-repeat;
  background-size: 100% 280px;
  height: 280px;
  width: 100%;

  @include for-phone-only() {
    height: 138px;
  }
}

.body {
  background-color: var(--white);
  flex: auto;
  width: 100%;
}

.logo {
  left: 80px;
  position: absolute;
  top: 30px;

  @include for-phone-only() {
    left: 30px;

    img {
      width: 120px;
    }
  }
}

.languageSwitcher {
  position: absolute;
  right: 80px;
  top: 35px;

  @include for-phone-only() {
    right: 30px;
  }
}

.title {
  margin-top: 152px;

  @include for-phone-only() {
    font-size: 28px !important;
    margin-top: 80px;
  }
}

.registerTitle {
  margin-bottom: 40px !important;
  margin-top: 40px !important;
}

.button {
  width: 50%;
}

.activeStep {
  background-color: var(--extra-light-green) !important;
  box-shadow: inset 0 3px 3px 0 rgb(8 59 34 / 6%);
}

.subTitle {
  color: var(--black);
  display: block;
  font-family: var(--font-serif);
  font-size: 20px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: -0.1px;
  line-height: 1.6;
  margin-bottom: 12px;
  margin-top: 12px;
}

.formWrapper {
  label {
    font-weight: bold;
  }
}

.forgotPasswordWrapper {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.customLink {
  font-size: 12px !important;
  margin: 0;
  padding: 0;

  span {
    text-decoration: underline;
  }

  &:hover {
    color: var(--green-3);

    span {
      text-decoration: none;
    }
  }
}

.inputWrapper {
  align-items: center;
  display: flex;
  gap: 30px;
}

.socialLoginTitle {
  color: var(--black);
  font-family: var(--font-sans);
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 16px;
  margin-top: 4px;
}
