.logoWrapper {
  display: flex;
  flex-direction: column;
  height: 40px;
  justify-content: center;
  text-decoration: none;
  width: 100%;

  .logoText {
    color: var(--white);
    font-family: var(--font-serif);
    font-size: 40px;
    text-align: center;
  }

  img {
    width: 145px;
  }
}
