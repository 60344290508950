.container {
  margin-bottom: 24px;
  margin-top: 24px;
}

.invalidRule,
.validRule {
  display: flex;
  font-family: var(--font-sans);
  font-size: 16px;
  line-height: 1.5;
  padding: 3px 0;

  .iconBlock {
    justify-self: flex-start;
    width: 24px;
  }

  .textBlock {
    margin-left: 12px;
    text-align: left;
  }
}

.invalidRule {
  color: var(--gray);
}

.validRule {
  color: var(--black);
}
