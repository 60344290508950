.wrapper {
  margin: 0 auto;
  max-width: 720px;
}

@media only screen and (max-width: 600px) {
  .wrapper {
    border: none;
    box-shadow: none;
    padding: 0;
  }
}
